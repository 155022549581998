
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/data/tag/BasicUsage.vue";
import EUIRemovableTag from "@/views/resources/documentation/element-ui/data/tag/RemovableTag.vue";
import EUIEditDynamically from "@/views/resources/documentation/element-ui/data/tag/EditDynamically.vue";
import EUISizes from "@/views/resources/documentation/element-ui/data/tag/Sizes.vue";
import EUITheme from "@/views/resources/documentation/element-ui/data/tag/Theme.vue";
import EUICheckableTag from "@/views/resources/documentation/element-ui/data/tag/CheckableTag.vue";

export default defineComponent({
  name: "tag",
  components: {
    EUIBasicUsage,
    EUIRemovableTag,
    EUIEditDynamically,
    EUISizes,
    EUITheme,
    EUICheckableTag
  },
  setup() {
    setCurrentPageTitle("Tag");
  }
});
